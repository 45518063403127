<!--下载中心详情-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <div class="breadTop">
      <el-row>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-row>
    </div>
    <!--内容部分-->
    <div class="content-div">
      <div class="titleDiv">
        <el-row>
          <el-col :span="24">
            <span class="news_title">{{title}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="news_time_reader">{{time}}</span>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24" style="min-height: 200px">
          <div class="w-e-text-container">
            <div v-html="newsData" data-slate-editor></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="bottom-div">
      <el-row v-show="fileList && fileList.length > 0">
        <el-col class="label_contain">
          <div class="news_labels">附件：</div>
          <div>
            <div v-for="(item, index) in fileList" :key="index" class="label_div">
              <span class="label_span cursorPointer" @click="downloadFile(item)">{{item.fileName}}</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <!--上一篇，下一篇-->
      <el-row>
        <el-col :span="24" class="rowSpaceBetween" style="margin-top: 20px">
          <div>
            <span class="prev_next_span">上一篇</span>
            <span v-if="previous && previous.contentTitle" class="otherNewClass cursorPointer" @click="toPreOrNext(previous.contentId)">{{previous.contentTitle}}</span>
            <span v-else class="noneNewClass">没有了</span>
          </div>
          <div>
            <span class="prev_next_span">下一篇</span>
            <span v-if="nextNew && nextNew.contentTitle" class="otherNewClass cursorPointer" @click="toPreOrNext(nextNew.contentId)">{{nextNew.contentTitle}}</span>
            <span v-else class="noneNewClass">没有了</span>
          </div>
        </el-col>
      </el-row>
      <!--增加取消下方白条-->
      <div style="height: 44px"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import topPathList from '@/components/topPathList'
import { downLoadFile } from '@/utils/commonApi'

export default {
  name: 'downloadCenterDetail',
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '资料下载',
          path: '/onlinebusiness/datadownload/index'
        },
        {
          name: '资料详情',
          path: '/onlinebusiness/datadownload/infoManageDetail'
        },
      ],
      title: '',
      time: '',
      newsData: '',
      previous: {},
      nextNew: {},
      contentId: "",
      fileList: [],
    }
  },
  mounted () {
    this.contentId = this.$route.query.id; //资料id
    this.getDetail()
  },
  methods: {
    //根据文章id查询文章详情
    getDetail() {
      let params = {
        id: this.contentId,
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/download/detail",
        params: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          const data = res.data.data
          this.title = data.infoName //标题
          this.time = data.createTime //日期
          this.newsData = data.infoDiscription //内容、
          this.previous = {
            contentId: data.previousId,
            contentTitle: data.previousName
          } //上一篇
          this.nextNew = {
            contentId: data.nextId,
            contentTitle: data.nextName
          } //下一篇
          this.fileList = data.fileList;
          this.$forceUpdate()
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },

    //上一篇
    toPreOrNext(contentId) {
      this.contentId = contentId
      this.getDetail()
      document.documentElement.scrollTop = 0;
    },
    downloadFile(file) {
      downLoadFile(file)
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.main-div {
  background-color: #f8f9fa !important;
}
.breadTop {
  width: 1200px;
  margin: auto;
}
.content-div {
  min-height: 324px;
  margin: auto;
  padding: 32px 40px 12px 40px;
  width: 1120px;
  background: #FFFFFF;
  .titleDiv {
    text-align: center;
  }
}
.news_title {
  font-size: 32px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
  margin-top: 52px;
}
.news_time_reader {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #888888;
  line-height: 21px;
}
.news_labels {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  color: #3E3A39;
  line-height: 24px;
}
.news_labels {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  color: #3E3A39;
  line-height: 24px;
  align-self: flex-start;
}
.label_div {
  text-align: left;
  .label_span {
    padding: 5px 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #186CF5;
    line-height: 28px;
  }
}
.label_contain {
  display: flex;
  align-items: center;
}
.bottom-div {
  margin: auto;
  width: 1120px;
}
.prev_next_span {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #3E3A39;
  line-height: 24px;
  margin-right: 10px;
}
.otherNewClass {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #186CF5;
  line-height: 24px;
}
.noneNewClass {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #B2B0B0;
  line-height: 24px;
}
.editContent {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #3E3A39;
  line-height: 21px;
}
::v-deep .el-row {
  margin: 20px 0;
}

::v-deep .temp_font {
  text-align: left;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #3E3A39;
  line-height: 30px;
  text-indent: 2rem;
}

@media screen and (max-width: 1366px) {
  .main-div {
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .content-div {
    margin: auto;
    padding: 32px 40px 12px 40px;
    width: 1120px;
    background: #FFFFFF;
    .titleDiv {
      text-align: center;
    }
  }
  .news_title {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    margin-top: 52px;
  }
  .news_time_reader {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #888888;
    line-height: 21px;
  }
  .news_labels {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #3E3A39;
    line-height: 24px;
  }
  .label_contain {
    display: flex;
    align-items: center;
  }
  .label_div {
    background: #E9F2FF;
    border-radius: 6px;
    margin: 5px;
    display: flex;
    float: left;

    .label_span {
      padding: 5px 10px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #186CF5;
      line-height: 28px;
    }
  }
  .bottom-div {
    margin: auto;
    width: 1120px;
  }
  .prev_next_span {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 24px;
    margin-right: 10px;
  }
  .otherNewClass {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #186CF5;
    line-height: 24px;
  }
  .noneNewClass {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #B2B0B0;
    line-height: 24px;
  }
  .editContent {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 21px;
  }
  ::v-deep .el-row {
    margin: 20px 0;
  }

  ::v-deep .temp_font {
    text-align: left;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 30px;
    text-indent: 2rem;
  }
}
</style>
